import { useQuery, UseQueryOptions } from "react-query";
import { getExplorerEndpoint } from "utils/getExplorerEndpoint";

export default function useContractABIQuery<TData = string>(
  contractAddress: string,
  networkId: string,
  options?: UseQueryOptions<string, Error, TData>
) {
  return useQuery<string, Error, TData>(
    ["abi"],
    async () => {
      const explorerBaseUrl = getExplorerEndpoint(networkId.toString());

      const etherscanApiKey = "ZSQQXA5P621JNAR8DXVME35RJEY4I4XTTU";
      const polygonscanApiKey = "T5U3GKRQB5HSKPM3WYX1X5D3H7DEEJCY3Z";
      let apiKey = etherscanApiKey;
      if (networkId.toString() === "137" || networkId.toString() === "80001")
        apiKey = polygonscanApiKey;

      const abiResponse = await fetch(
        `${explorerBaseUrl}/api?module=contract&action=getabi&address=${contractAddress}&apikey=${apiKey}`
      );
      const abi = await abiResponse.json();
      return abi.result || undefined;
    },
    {
      ...options,
      enabled: !!contractAddress.match(/^0x[a-fA-F0-9]{40}$/),
    }
  );
}
