export const allowedNetworks = [
  {
    title: "Ethereum Mainnet",
    id: "1",
    currency: "ETH",
    url: "https://etherscan.io/tx/",
    disabled: false,
  },
  {
    title: "Ethereum Ropsten",
    id: "3",
    currency: "ETH",
    url: "https://ropsten.etherscan.io/tx/",
    disabled: true,
  },
  {
    title: "Ethereum Kovan",
    id: "42",
    currency: "ETH",
    url: "https://kovan.etherscan.io/tx/",
    disabled: true,
  },
  {
    title: "Ethereum Rinkeby",
    id: "4",
    currency: "ETH",
    url: "https://rinkeby.etherscan.io/tx/",
    disabled: true,
  },
  {
    title: "Ethereum Goerli",
    id: "5",
    currency: "ETH",
    url: "https://goerli.etherscan.io/tx/",
    disabled: false,
  },
  {
    title: "Polygon Mainnet",
    id: "137",
    currency: "MATIC",
    url: "https://polygonscan.com/tx/",
    disabled: false,
  },
  {
    title: "Polygon Testnet",
    id: "80001",
    currency: "MATIC",
    url: "https://mumbai.polygonscan.com/tx/",
    disabled: false,
  },
  {
    title: "Polygon zkEVM Mainnet",
    id: "1101",
    currency: "ETH",
    url: "https://zkevm.polygonscan.com/tx/",
    disabled: false,
  },
  {
    title: "Polygon zkEVM Testnet",
    id: "1442",
    currency: "ETH",
    url: "https://testnet-zkevm.polygonscan.com/tx/",
    disabled: false,
  },
  {
    title: "Gnosis Chain Mainnet",
    id: "100",
    currency: "GNO",
    url: "https://blockscout.com/xdai/mainnet/tx/",
    disabled: false,
  },
  {
    title: "BSC Mainnet",
    id: "56",
    currency: "BNB",
    url: "https://bscscan.com/tx/",
    disabled: false,
  },
  {
    title: "BSC Testnet",
    id: "97",
    currency: "BNB",
    url: "https://testnet.bscscan.com/tx/",
    disabled: false,
  },
  {
    title: "Moonbeam Mainnet",
    id: "1284",
    currency: "GLMR",
    url: "https://moonscan.io/tx/",
    disabled: false,
  },
  {
    title: "Moonriver Mainnet",
    id: "1285",
    currency: "MOVR",
    url: "https://moonriver.moonscan.io/tx/",
    disabled: false,
  },
  {
    title: "Moonbeam Testnet",
    id: "1287",
    currency: "GLMR",
    url: "https://moonbase-blockscout.testnet.moonbeam.network/tx/",
    disabled: false,
  },
  {
    title: "Arbitrum Mainnet",
    id: "42161",
    currency: "ETH",
    url: "https://arbiscan.io/tx/",
    disabled: false,
  },
  {
    title: "Arbitrum Testnet",
    id: "421611",
    currency: "ETH",
    url: "https://testnet.arbiscan.io/tx/",
    disabled: false,
  },
  {
    title: "Avalanche Testnet C-Chain",
    id: "43113",
    currency: "AVAX",
    url: "https://testnet.snowtrace.io/tx/",
    disabled: false,
  },
  {
    title: "Avalanche Mainnet C-Chain",
    id: "43114",
    currency: "AVAX",
    url: "https://snowtrace.io/tx/",
    disabled: false,
  },
  {
    title: "Fantom Mainnet",
    id: "250",
    currency: "FTM",
    url: "https://ftmscan.com/tx/",
    disabled: false,
  },
  {
    title: "Fantom Testnet",
    id: "4002",
    currency: "FTM",
    url: "https://testnet.ftmscan.com/tx/",
    disabled: false,
  },
  {
    title: "Optimism Testnet",
    id: "420",
    currency: "ETH",
    url: "https://blockscout.com/optimism/goerli/tx/",
    disabled: false,
  },
  {
    title: "Optimism Mainnet",
    id: "10",
    currency: "ETH",
    url: "https://optimistic.etherscan.io/tx/",
    disabled: false,
  },
  {
    title: "Celo Alfajores Testnet",
    id: "44787",
    currency: "CELO",
    url: "https://alfajores-blockscout.celo-testnet.org/tx/",
    disabled: false,
  },
  {
    title: "Celo Mainnet",
    id: "42220",
    currency: "CELO",
    url: "https://explorer.celo.org/tx/",
    disabled: false,
  },
  {
    title: "Neon Devnet",
    id: "245022926",
    currency: "NEON",
    url: "https://blockscout.com/optimism/goerli/tx/",
    disabled: false,
  },
  {
    title: "Bobabeam Mainnet",
    id: "1294",
    currency: "BOBA",
    url: "https://blockexplorer.bobabeam.boba.network/tx/",
    disabled: false,
  },
  {
    title: "OKBC Testnet",
    id: "195",
    currency: "OKB",
    url: "https://www.oklink.com/okbc-test/tx/",
    disabled: false,
  },
  {
    title: "Mantle Mainnet",
    id: "5000",
    currency: "MNT",
    url: "https://explorer.mantle.xyz/tx/",
    disabled: false,
  },
  {
    title: "Mantle Testnet",
    id: "5001",
    currency: "MNT",
    url: "https://explorer.testnet.mantle.xyz/tx/",
    disabled: false,
  },
  {
    title: "Meter Mainnet",
    id: "82",
    currency: "MTR",
    url: "https://scan.meter.io/",
    disabled: false,
  },
  {
    title: "Meter Testnet",
    id: "83",
    currency: "MTR",
    url: "https://scan-warringstakes.meter.io/",
    disabled: false,
  }
];

export const isTestnet = (networkId: string) => {
  const testent = [
    "3",
    "42",
    "4",
    "5",
    "80001",
    "97",
    "1287",
    "421611",
    "43113",
    "4002",
    "1442",
    "195",
    "5001",
    "83"
  ];
  if (testent.includes(networkId)) return "Testnet";
  return "Mainnet";
};

export const getNetworkDetail = (
  networkId: string,
  detail: "title" | "id" | "currency" | "url"
) => {
  // eslint-disable-next-line eqeqeq
  const ind = allowedNetworks.findIndex((network) => network.id == networkId);
  if (ind === -1) return "Unknown";
  return allowedNetworks[ind][detail];
};

// export const getExplorerURL = (networkId: number, txHash: string) => {
//   return `${explorerURLMap}/tx/${txHash}`;
// };
// export const explorerURLMap = {
//   1: "https://etherscan.io/tx/",
//   3: "https://ropsten.etherscan.io/tx/",
//   4: "https://rinkeby.etherscan.io/tx/",
//   5: "https://goerli.etherscan.io/tx/",
//   42: "https://kovan.etherscan.io/tx/",
//   56: "https://bscscan.com/tx/",
//   100: "https://blockscout.com/xdai/mainnet/tx/",
//   137: "https://polygonscan.com/tx/",
//   80001: "https://mumbai.polygonscan.com/tx/",
//   97: "https://testnet.bscscan.com/tx/",
//   43114: "https://snowtrace.io/tx/",
//   43113: "https://testnet.snowtrace.io/tx/",
//   421611: "https://testnet.arbiscan.io/tx/",
//   42161: "https://arbiscan.io/tx/",
//   1284: "https://moonscan.io/tx/",
//   250: "https://ftmscan.com/tx/",
//   4002: "https://testnet.ftmscan.com/tx/",
//   1285: "https://moonriver.moonscan.io/tx/",
//   1287: "https://moonbase-blockscout.testnet.moonbeam.network/tx/",
// };

export type networkType =
  | "1"
  | "3"
  | "4"
  | "5"
  | "42"
  | "137"
  | "80001"
  | "100"
  | "56"
  | "97"
  | "1284"
  | "1285"
  | "1287"
  | "42161"
  | "421611"
  | "43113"
  | "43114"
  | "250"
  | "4002"
  | "1101"
  | "1442"
  | "195"
  | "5001"
  | "5000"
  | "82"
  | "83";

export const allowedCondtionalNetworks = [
  {
    title: "Ethereum Mainnet",
    id: "1",
    currency: "ETH",
    url: "https://etherscan.io/tx/",
  },
  {
    title: "Ethereum Rinkeby",
    id: "4",
    currency: "ETH",
    url: "https://rinkeby.etherscan.io/tx/",
  },
  {
    title: "Ethereum Goerli",
    id: "5",
    currency: "ETH",
    url: "https://goerli.etherscan.io/tx/",
  },
  {
    title: "Polygon Mainnet",
    id: "137",
    currency: "MATIC",
    url: "https://polygonscan.com/tx/",
  },
  {
    title: "Polygon Testnet",
    id: "80001",
    currency: "MATIC",
    url: "https://mumbai.polygonscan.com/tx/",
  },
];
