let config: any = {};

if (process.env.REACT_APP_STAGE === "prod") {
  config.apiUrl = "https://api.biconomy.io";
  config.getNetworksUrl = "https://api.biconomy.io/api/v1/admin/getNetworks";
  config.getAdminNetworksUrl =
    "https://api.biconomy.io/api/v1/admin/getAdminNetworks";
  config.getRelayersInfoUrl =
    "https://api.biconomy.io/api/v1/admin/relayersInfo";
  config.getGasTokenBalanceUrl =
    "https://api.biconomy.io/api/v1/admin/get-balance";
  config.getDataUrl = "https://data.biconomy.io";
  config.gasTankGatewayUrl = "https://gas-manager.biconomy.io";
} else if (process.env.REACT_APP_STAGE === "staging") {
  config.apiUrl = "https://staging-api.biconomy.io";
  config.getNetworksUrl =
    "https://staging-api.biconomy.io/api/v1/admin/getNetworks";
  config.getAdminNetworksUrl =
    "https://staging-api.biconomy.io/api/v1/admin/getAdminNetworks";
  config.getRelayersInfoUrl =
    "https://staging-api.biconomy.io/api/v1/admin/relayersInfo";
  config.getGasTokenBalanceUrl =
    "https://staging-api.biconomy.io/api/v1/admin/get-balance";
  config.getDataUrl = "https://staging-data.biconomy.io";
  config.gasTankGatewayUrl = "https://staging-gas-manager.biconomy.io";
} else {
  config.apiUrl = "https://localhost:80";
  config.getNetworksUrl = "https://localhost:80/api/v1/admin/getNetworks";
  config.getAdminNetworksUrl =
    "https://localhost:80/api/v1/admin/getAdminNetworks";
  config.getRelayersInfoUrl = "https://localhost:80/api/v1/admin/relayersInfo";
  config.getGasTokenBalanceUrl =
    "https://localhost:80/api/v1/admin/get-balance";
  config.getDataUrl = "https://localhost:3000";
  config.gasTankGatewayUrl = "https://localhost:3006";
}

config.network = {
  "1": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_ETHEREUM_MAINNET,
  },
  "4": {
    gasTankAddresses: process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_RINKEBY,
  },
  "5": {
    gasTankAddresses: process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_GOERLI,
  },
  "42": {
    gasTankAddresses: process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_KOVAN,
  },
  "80001": {
    gasTankAddresses: process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_MUMBAI,
  },
  "137": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_POLYGON_MAINNET,
  },
  "56": {
    gasTankAddresses: process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_BSC_MAINNET,
  },
  "97": {
    gasTankAddresses: process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_BSC_TESTNET,
  },
  "100": {
    gasTankAddresses: process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_XDAI_MAINNET,
  },
  "43114": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_AVALANCHE_MAINNET,
  },
  "43113": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_AVALANCHE_TESTNET,
  },
  "250": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_FANTOM_MAINNET,
  },
  "4002": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_FANTOM_TESTNET,
  },
  "42161": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_ARBITRUM_MAINNET,
  },
  "421611": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_ARBITRUM_TESTNET,
  },
  "1285": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_MOONRIVER_MAINNET,
  },
  "1284": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_MOONBEAM_MAINNET,
  },
  "1287": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_MOONBEAM_TESTNET,
  },
  "420": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_OPTIMISM_TESTNET,
  },
  "10": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_OPTIMISM_MAINNET,
  },
  "42220": {
    gasTankAddresses: process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_CELO_MAINNET,
  },
  "44787": {
    gasTankAddresses: process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_CELO_TESTNET,
  },
  "245022926": {
    gasTankAddresses: process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_NEON_DEVNET,
  },
  "1294": {
    gasTankAddresses: process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_BOBABEAM,
  },
  "1101": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_POLYGON_ZKEVM_MAINNET,
  },
  "1442": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_POLYGON_ZKEVM_TESTNET,
  },
  "195": {
    gasTankAddresses: process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_OKBC_TESTNET,
  },
  "5001": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_MANTLE_TESTNET,
  },
  "5000": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_MANTLE_MAINNET,
  },
  "82": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_METER_MAINNET,
  },
  "83": {
    gasTankAddresses:
      process.env.REACT_APP_DAPP_GASTANK_ADDRESSES_METER_TESTNET,
  }
};

config.grafana = {
  host: "http://grafana.biconomy.io",
  path: "/d/7bhWE-0Zz/developer-dashboard?orgId=1&refresh=5s&var-dappId=",
};

config.apiVersion = "v1";
config.apiVersion2 = "v2";
config.SCW = "SCW";
config.gasTokenSupportedNetworks = [];
config.mainnetAllowedNetworks = [
  "1",
  "100",
  "137",
  "56",
  "2021",
  "1285",
  "43114",
  "250",
  "42161",
  "1284",
  "2021",
  "1101",
  "5000",
  "82"
];

config.gnosisWalletSupportedNetworks = ["1", "5", "100", "56", "137"];
config.biconomyWalletSupportedNetworks = ["137", "80001", "5"];

config.maticWidgetSupportedNetworks = ["137", "80001"];
config.erc20ForwarderSupportedNetworks = ["1", "42", "4", "80001", "137"];
config.trustedForwarderSupportedNetworks = [
  "1",
  "137",
  "100",
  "80001",
  "42",
  "5",
  "4",
  "3",
  "97",
  "1287",
  "56",
  "2021",
  "421611",
  "42161",
  "1284",
  "1285",
  "43113",
  "43114",
  "250",
  "4002",
  "1101",
  "1442",
  "195",
  "5001",
  "5000",
  "82",
  "83"
];
config.gasTankSupportedNetworks = [
  "1",
  "42",
  "137",
  "80001",
  "100",
  "56",
  "43114",
  "43113",
  "250",
  "4002",
  "421611",
  "42161",
  "1285",
  "1284",
  "1287",
  "1101",
  "1442",
  "195",
  "5001",
  "5000",
  "82",
  "83"
];

config.PAGE = {
  HOME: "HOME",
  DAPPS: "DAPPS",
  TRANSACTIONS: "TRANSACTIONS",
};

config.constants = {
  ALL: "ALL",
  NETWORK_FILTER: "network",
  HAS_BETA_ACCESS: "hasBetaAccess",
};

config.metaTransactionTypeMap = {
  DEFAULT: "Custom",
  ERC20_FORWARDER: "ERC20 Forwarder",
  TRUSTED_FORWARDER: "Trusted Forwarder",
};

config.abi = {
  dappGasTank: [
    { inputs: [], stateMutability: "nonpayable", type: "constructor" },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "sender",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "fundingKey",
          type: "uint256",
        },
      ],
      name: "Deposit",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "token",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "actor",
          type: "address",
        },
      ],
      name: "DepositTokenAdded",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "account",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "actor",
          type: "address",
        },
      ],
      name: "MasterAccountChanged",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "uint256",
          name: "minDeposit",
          type: "uint256",
        },
        {
          indexed: true,
          internalType: "address",
          name: "actor",
          type: "address",
        },
      ],
      name: "MinimumDepositChanged",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "truestedForwarder",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "actor",
          type: "address",
        },
      ],
      name: "TrustedForwarderChanged",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "actor",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
        {
          indexed: true,
          internalType: "address",
          name: "receiver",
          type: "address",
        },
      ],
      name: "Withdraw",
      type: "event",
    },
    {
      inputs: [],
      name: "_trustedForwarder",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "allowedTokens",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      name: "dappBalances",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_fundingKey", type: "uint256" },
      ],
      name: "depositFor",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "", type: "address" },
        { internalType: "uint256", name: "", type: "uint256" },
      ],
      name: "depositorBalances",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "trustedForwarder", type: "address" },
      ],
      name: "initialize",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "forwarder", type: "address" }],
      name: "isTrustedForwarder",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "masterAccount",
      outputs: [{ internalType: "addresspayable", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "minDeposit",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "addresspayable",
          name: "_newAccount",
          type: "address",
        },
      ],
      name: "setMasterAccount",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_newMinDeposit", type: "uint256" },
      ],
      name: "setMinDeposit",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "token", type: "address" },
        { internalType: "bool", name: "allowed", type: "bool" },
      ],
      name: "setTokenAllowed",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "addresspayable", name: "_forwarder", type: "address" },
      ],
      name: "setTrustedForwarder",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "tokenPriceFeed",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
      name: "withdraw",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    { stateMutability: "payable", type: "receive" },
  ],
};

config.networkIdToNetworkName = {
  "1": "Ethereum Mainnet",
  "3": "Ropsten Testnet",
  "5": "Goerli Testnet",
  "42": "Kovan TestNet",
  "56": "BSC Mainnet",
  "100": "Gnosis Chain Mainnet",
  "137": "Polygon Mainnet",
  "80001": "Polygon Mumbai Testnet",
  "421611": "Arbitrum Testnet",
  "42161": "Arbitrum Mainnet",
  "1285": "Moonriver Mainnet",
  "250": "Fantom Mainnet",
  "4002": "Fantom Testnet",
  "1287": "Moonbeam Testnet",
  "1284": "Moonbeam Mainnet",
  "43113": "Avalanche Testnet C-Chain",
  "43114": "Avalanche Mainnet C-Chain",
  "1101": "Polygon zkEVM Mainnet",
  "1442": "Polygon zkEVM Testnet",
  "195": "OKBC Testnet",
  "5001": "Mantle Testnet",
  "5000": "Mantle Mainnet",
  "82": "Meter Mainnet",
  "83": "Meter Testnet"
};

config.getExplorerURL = (hash: string, chainId: number) => {
  return `${config.explorerURLMap[chainId]}${hash}`;
};

config.explorerURLMap = {
  1: "https://etherscan.io/tx/",
  3: "https://ropsten.etherscan.io/tx/",
  4: "https://rinkeby.etherscan.io/tx/",
  5: "https://goerli.etherscan.io/tx/",
  42: "https://kovan.etherscan.io/tx/",
  56: "https://bscscan.com/tx/",
  100: "https://blockscout.com/xdai/mainnet/tx/",
  137: "https://polygonscan.com/tx/",
  80001: "https://mumbai.polygonscan.com/tx/",
  97: "https://testnet.bscscan.com/tx/",
  43114: "https://snowtrace.io/tx/",
  43113: "https://testnet.snowtrace.io/tx/",
  421611: "https://testnet.arbiscan.io/tx/",
  42161: "https://arbiscan.io/tx/",
  1284: "https://moonscan.io/tx/",
  250: "https://ftmscan.com/tx/",
  4002: "https://testnet.ftmscan.com/tx/",
  1285: "https://moonriver.moonscan.io/tx/",
  1287: "https://moonbase-blockscout.testnet.moonbeam.network/tx/",
  1101: "https://zkevm.polygonscan.com/tx/",
  1442: "https://testnet-zkevm.polygonscan.com/tx/",
  195: "https://www.oklink.com/okbc-test/tx/",
  5001: "https://explorer.testnet.mantle.xyz/tx/",
  5000: "https://explorer.mantle.xyz/tx",
  82: "https://scan.meter.io/tx",
  83: "https://scan-warringstakes.meter.io/tx"
};

config.selectedWalletKey = "io.biconomy.dashboard.selectedWallet.name";

config.metamaskErrorValues = {
  UNPREDICTABLE_GAS_LIMIT: {
    standard: "JSON RPC 2.0",
    message: "Must be grater than minimum deposit for this network",
  },
  "-32700": {
    standard: "JSON RPC 2.0",
    message:
      "Invalid JSON was received by the server. An error occurred on the server while parsing the JSON text",
  },
  "-32600": {
    standard: "JSON RPC 2.0",
    message: "The JSON sent is not a valid Request object",
  },
  "-32601": {
    standard: "JSON RPC 2.0",
    message: "The method does not exist / is not available",
  },
  "-32602": {
    standard: "JSON RPC 2.0",
    message: "Invalid method parameter(s)",
  },
  "-32603": {
    standard: "JSON RPC 2.0",
    message: "Internal JSON-RPC error",
  },
  "-32000": {
    standard: "EIP-1474",
    message: "Invalid input",
  },
  "-32001": {
    standard: "EIP-1474",
    message: "Resource not found",
  },
  "-32002": {
    standard: "EIP-1474",
    message: "Resource unavailable",
  },
  "-32003": {
    standard: "EIP-1474",
    message: "Transaction rejected",
  },
  "-32004": {
    standard: "EIP-1474",
    message: "Method not supported",
  },
  "-32005": {
    standard: "EIP-1474",
    message: "Request limit exceeded",
  },
  "4001": {
    standard: "EIP-1193",
    message: "User rejected the request",
  },
  "4100": {
    standard: "EIP-1193",
    message:
      "The requested account and/or method has not been authorized by the user",
  },
  "4200": {
    standard: "EIP-1193",
    message: "The requested method is not supported by this Ethereum provider",
  },
  "4900": {
    standard: "EIP-1193",
    message: "The provider is disconnected from all chains",
  },
  "4901": {
    standard: "EIP-1193",
    message: "The provider is disconnected from the specified chain",
  },
};

config.decimal = {
  8995: 18,
  80001: 18,
  137: 18,
  3: 18,
  4: 18,
  5: 18,
  16110: 18,
  15001: 18,
  42: 18,
  1: 18,
  77: 18,
  100: 18,
  31: 8,
  43113: 18,
  43114: 18,
  97: 18,
  1287: 18,
  56: 18,
  2021: 18,
  421611: 18,
  42161: 18,
  1285: 18,
  1284: 18,
  250: 18,
  4002: 18,
  1101: 18,
  1442: 18,
  195: 18,
  5001: 18,
  5000: 18,
  82: 18,
  83: 18
};

export default config;
